<template>
  <aRow class="rooms">
    <aCol class="search" :span="24">
      <aRow type="flex" align="middle">
        <aCol flex="auto">
          <a-input
            placeholder="Buscar conversa"
            @change="onSearchRooms"
            allowClear
          >
            <template #prefix>
              <a-icon type="search" />
            </template>
          </a-input>
        </aCol>
        <aCol class="add-room" flex="32px">
          <a-icon class="add-btn" type="plus" @click="onClickAddRoom" />
        </aCol>
      </aRow>
    </aCol>

    <aCol class="list" :span="24">
      <aRow v-if="rooms.length" class="tabs" :gutter="10">
        <aCol :span="12">
          <div
            class="tab"
            :class="activeRoomTab == 'active' ? 'active' : ''"
            @click="onClickChangeActiveRoomTab('active')"
          >
            Ativo
          </div>
        </aCol>
        <aCol :span="12">
          <div
            class="tab"
            :class="activeRoomTab == 'archived' ? 'active' : ''"
            @click="onClickChangeActiveRoomTab('archived')"
          >
            Arquivado
          </div>
        </aCol>
      </aRow>

      <aRow align="middle" type="flex" justify="space-around">
        <aCol
          class="room"
          v-for="(room, index) in rooms"
          :key="index"
          :span="24"
        >
          <div v-if="!loadingRooms">
            <div class="card" @click="onClickRoomCard(room)">
              <aRow align="middle" type="flex" justify="space-around">
                <aCol :span="5">
                  <a-avatar icon="user" :src="room.avatar" :size="50" />
                </aCol>
                <aCol :span="19">
                  <aRow>
                    <aCol :span="24" class="name">
                      {{ room.roomName }}

                    </aCol>
                    <aCol :span="24" class="extra">
                      <aRow type="flex" justify="space-between">
                        <aCol class="last-msg">
                          <div class="text" v-if="room.typingUsers.length">
                            {{
                              formatTypingUsers(room.users, room.typingUsers)
                            }}
                          </div>
                          <div v-else>
                            <div
                              class="text"
                              v-if="room.lastMessage"
                              :title="room.lastMessage"
                            >
                              <font class="status">
                                <a-icon class="check-one" type="check" />
                                <a-icon class="check-two" type="check" />
                              </font>
                              {{ room.lastMessage }}
                            </div>
                          </div>
                        </aCol>
                        <aCol class="counter">
                          <a-badge
                            :count="room.unreadCount"
                            :number-style="{ backgroundColor: '#a17be0' }"
                          />
                        </aCol>
                      </aRow>
                    </aCol>
                  </aRow>
                </aCol>
              </aRow>
            </div>
            <div class="room-actions" @click="activeRoomAction = room.roomId">
              <ChatContextMenu
                :id="room.roomId"
                :isCurrent="activeRoomAction === room.roomId"
                :showRight="true"
                :menuItems="[
                  {
                    label: 'Marcar como não lido',
                    ico: 'eye-invisible',
                    action: 'unread-msg',
                    show: false,
                  },
                  {
                    label: 'Marcar como lido',
                    ico: 'eye',
                    action: 'read-msg',
                    show: false,
                  },
                  {
                    label: 'Arquivar',
                    ico: 'container',
                    action: 'archive',
                    show: activeRoomTab == 'active',
                  },
                  {
                    label: 'Desarquivar',
                    ico: 'container',
                    action: 'unarchive',
                    show:
                      activeRoomTab == 'archived' &&
                      currentUserId === room.user,
                  },
                  {
                    label: 'Apagar',
                    ico: 'delete',
                    action: 'delete',
                    show: currentUserId === room.user,
                  },
                ]"
                @onClickMenuItem="onClickMenuItem"
              >
                <span class="arrow"> <a-icon type="down" /></span>
              </ChatContextMenu>
            </div>
          </div>
        </aCol>
        <span v-if="loadingRooms" :span="24">
          <a-icon class="loading-room" type="loading" />
        </span>
      </aRow>
    </aCol>
  </aRow>
</template>

<script>
import ChatContextMenu from "./ChatContextMenu.vue";
export default {
  components: { ChatContextMenu },
  name: "ChatRooms",
  props: {
    rooms: Array,
    loadingRooms: Boolean,
    currentUserId: String,
  },
  data() {
    return {
      activeRoomAction: "",
      activeRoomTab: "active",
    };
  },
  mounted() {},
  methods: {
    onSearchRooms(e) {
      this.$emit("onSearchRooms", e.target.value);
    },
    onClickRoomCard(room) {
      this.$emit("onClickRoomCard", room);
    },
    onClickArchiveRoom(id) {
      this.$emit("onClickArchiveRoom", id);
    },
    onClickUnarchiveRoom(id) {
      this.$emit("onClickUnarchiveRoom", id);
    },
    onClickDeleteRoom(id) {
      this.$emit("onClickDeleteRoom", id);
    },
    onClickAddRoom() {
      this.$emit("onClickAddRoom");
    },
    onClickChangeActiveRoomTab(tab) {
      this.activeRoomTab = tab;
      this.$emit("onClickChangeActiveRoom", tab);
    },
    onClickMenuItem(id, action) {
      if (action === "archive") this.onClickArchiveRoom(id);
      if (action === "unarchive") this.onClickUnarchiveRoom(id);
      if (action === "delete") this.onClickDeleteRoom(id);
    },
    formatTypingUsers(users, typingUsers) {
      const areAllEqual = users.every(
        (value) => value.username === users[0].username
      );

      let usersTyping = "";

      if (!areAllEqual) {
        users.forEach((user) => {
          if (typingUsers.includes(user._id)) {
            usersTyping += `${user.username} está digitando...  `;
          }
        });
      }

      return usersTyping.slice(0, -2);
    },
  },
};
</script>

<style lang="sass" scoped>
.rooms
  height: 100%
  .loading-room
    font-size: 60px
    color: #ddd
    margin-top: 50px
  .add-room
    text-align: right
    .add-btn
      background: #a17be0
      padding: 4px
      border-radius: 30px
      color: #FFF
      font-size: 12px
      cursor: pointer
  .search
    height: 64px
    padding: 15px
    border-bottom: 1px solid #eee
  .list
    padding: 5px 10px 5px
    overflow: auto
    height: calc(100% - 64px)
    .tabs
      text-align: center
      border-bottom: 1px solid #eee
      margin-bottom: 10px
      padding-bottom: 5px
      .tab
        padding: 5px 10px
        font-size: 12px
        font-weight: 500
        cursor: pointer
        background: #fff
        border-radius: 6px
        transition: .3s
      .tab:hover
        background: #fafafa
      .tab.active
        background: #fafafa
    .room
      padding: 10px
      margin-bottom: 5px
      border-radius: 8px
      transition: background-color .3s cubic-bezier(.25,.8,.5,1)
      cursor: pointer
      position: relative
      .card
        width: 100%
      .room-actions
        position: absolute
        right: 10px
        bottom: 12px
        .arrow
          color: #aaa
          background: #fff
          width: 24px
          height: 24px
          text-align: center
          line-height:  28px
          border-radius: 30px
          font-size: 13px
          display: inline-block
          transition: .3s
        .arrow:hover
          background: #fff
      .name
        font-weight: 600
      .counter
        margin-right: 30px
      .last-msg
        .text
          width: 170px
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          font-size: 12px
        .status
          color: #bbb
          .check-two
              margin-left: -12px
      &:hover
        background: #eee
</style>
